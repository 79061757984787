
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('barc_config.grant_setup') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('barc_config.grant_type')"
                            label-for="grand_type_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.grand_type_id"
                                :options="grantTypeList"
                                id="grand_type_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('barc_config.grant_name')"
                        label-for="grant_name"
                        >
                        <v-select
                          name="grant_name"
                          v-model="search.grant_id"
                          label= "text"
                          :options= grantLists
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('barc_config.grant_setup_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(grant_id)="data">
                                            {{ $n(data.item.grant_id, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(amount)="data">
                                            {{ $n(data.item.amount, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { GrantSetupList, GrantSetupStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: '0',
        grand_type_id: '0',
        grant_name: ''
      },
      rows: [],
      countryList: [],
      item: '',
      grant_types: [
          {
              value: 1,
              text: 'National',
              text_bn: 'জাতীয়'
          },
          {
              value: 2,
              text: 'International',
              text_bn: 'আন্তর্জাতিক'
          }
      ]
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    grantLists: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.grantList.filter(item => item.status === 1)
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    grantTypeList: function () {
        return this.grant_types.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('barc_config.grant_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('barc_config.grant_setup') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('barc_config.grant_id'), class: 'text-left' },
          { label: this.$t('barc_config.grant_type'), class: 'text-left' },
          { label: this.$t('barc_config.grant_name'), class: 'text-left' },
          { label: this.$t('barc_config.grant_amount'), class: 'text-left' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
          { label: this.$t('globalTrans.description'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'grant_id' },
          { key: 'grant_type_name_bn' },
          { key: 'grant_name_bn' },
          { key: 'amount' },
          { key: 'fiscal_year_name_bn' },
          { key: 'description_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'grant_id' },
          { key: 'grant_type_name' },
          { key: 'grant_name' },
          { key: 'amount' },
          { key: 'fiscal_year_name' },
          { key: 'description' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, GrantSetupStatus, item, 'incentive_grant', 'GrantSetupList')
    },
    loadData () {
      var grantID = ''
      if (this.search.grant_id) {
          grantID = this.search.grant_id.value
      }
      const params = Object.assign({}, this.search, { grant_id: grantID, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, GrantSetupList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const grantTypeList = this.grant_types

      const listData = data.map(item => {
        const fiscalYearObject = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const grantTypeObject = grantTypeList.find(grantType => grantType.value === item.grand_type_id)

        const fiscalYearData = { fiscal_year_name: fiscalYearObject.text_en, fiscal_year_name_bn: fiscalYearObject.text_bn }
        const grantTypeData = { grant_type_name: grantTypeObject.text, grant_type_name_bn: grantTypeObject.text_bn }

        return Object.assign({}, item, fiscalYearData, grantTypeData)
      })
      return listData
    }
  }
}
</script>
